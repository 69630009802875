angular.module("aerosApp").controller("LicenseCtrl", 
[ "$scope", "aerosApi", '$uibModal', 'Notification',
  function($scope, aerosApi, $uibModal, Notification) {
	$scope.$watch("commonReady", function (newVal) {
		if (!newVal) return;
		if (!$scope.allow['viewLicense']) return $scope.reject();
			// TODO: pass in as constant
			$scope.contact = {
					"Email": "aeros.support@aflglobal.com",
					"Phone": "1-800-321-5298: press option 3"
			};
			$scope.showContact = function () {
				$uibModal.open({
                    size: "md",
				      templateUrl: 'contactInfoModal.html',
				      resolve: {
				        contact: function () {
				          return $scope.contact;
				        }
				      },
				      controller: ["$scope", "contact", function($scope, contact){
				    	  $scope.contact = contact;
				      }]
				    });
			};
	});
}]);